// libs
import React, { memo } from 'react';
import { NewRelicScriptHelper } from './helpers';
import Helmet from 'react-helmet';

// hooks
import { useSSRExperimentVariant } from '@nykaa/experiment-sdk/store/hooks';

const NewRelicBrowser = () => {
  const { isEligible = false } = useSSRExperimentVariant('new-relic-client');
  const { NEW_RELIC_APP_KEY: applicationID = '' } = process.env;

  if (!isEligible || !applicationID) return null;

  const NewRelicScript = NewRelicScriptHelper(`${applicationID}`);

  return (
    <Helmet
      script={[
        {
          type: 'text/Javascript',
          innerHTML: NewRelicScript,
        },
      ]}
    ></Helmet>
  );
};

export default memo(NewRelicBrowser);
